// Do not append more variables! This is necessary to incrementally refactor custom.js
// Following are loaded as dynamic modules, because they have dependencies, only provided in some pages.
// Offer needs TableMultiselect, but "~public/js/src/salon/table-multiselect.js" is only loaded on some routes (e.g. intern/offer)
// Loading it normally, would throw an error, because TableMultiselect is not defined.

export const loadModule = {
  get deliveryNote() {
    return import('../../../js/src/salon/delivery_note.js').then((module) => module.deliveryNote)
  },
  get accounting() {
    return import('../../../js/src/salon/business/default/services/accounting.js').then((module) => module.accounting)
  },
  get gastro() {
    return import('../../../js/src/salon/business/gastro/invoice-new.js').then((module) => module.gastro)
  },
  get invoice() {
    return import('../../../js/src/salon/business/default/invoice-new.js').then((module) => module.invoice)
  },
  get offer() {
    return import('../../../js/src/salon/offer.js').then((module) => module.offer)
  },
  get payment() {
    return import('../../../js/src/salon/business/gastro/invoice-new.js').then((module) => module.payment)
  },
  get voucher() {
    return import('../../../js/src/salon/business/default/services/voucher.js').then((module) => module.voucher)
  },
  get voucherEncashment() {
    return import('../../../js/src/salon/business/default/invoice-new.js').then((module) => module.voucherEncashment)
  },
  get article() {
    return import('../../../js/src/salon/business/default/services/article.js').then((module) => module.article)
  },
  get service() {
    return import('../../../js/src/salon/business/default/services/service.js').then((module) => module.service)
  },
  get stock() {
    return import('../../../js/src/salon/business/default/services/stock.js').then((module) => module.stock)
  },
}
